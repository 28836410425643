import React, { useContext } from "react";
import { GlobalContext } from "../layout/Index";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const SearchNews = () => {
  const { newsData, setNewsData } = useContext(GlobalContext);
  function handleImageError(image) {
    // Replace with a placeholder image
    image.src = "https://example.com/placeholder.jpg";

    // Display an error message
    console.log("Image failed to load: " + image.src);
  }

  return (
    <section className="section-news w-100 pb-8">
      <div className="container">
        {newsData === null ? (
          <div className="row">
            <div className="col-md-4">
              <ShimmerThumbnail height={300} width="100%" />
            </div>
            <div className="col-md-4">
              <ShimmerThumbnail height={300} width="100%" />
            </div>
            <div className="col-md-4">
              <ShimmerThumbnail height={300} width="100%" />
            </div>
          </div>
        ) : (
          <>
            <div className="row flex-wrap news-blocks">
              {newsData.map((item, index) => (
                <div className="col-md-6 col-lg-4 mb-3" key={index}>
                  <Link to={`https://news.silocloud.io/news/${item?.id}`}>
                    <div target="_blank" className="card h-100 position-relative pointer">
                      <div className="">
                        <img src={item?.image} alt={item?.title} className="w-100" />
                      </div>
                      <div className="card-body position-absolute">
                        <h6 className="truncated-text-head">{item?.title}</h6>
                        <p className="mb-1 truncated-text-summary">{item?.summary}</p>
                        <small>{item?.publish_date}</small>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default SearchNews;
