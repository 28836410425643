import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import AppDropdown from "./dropdown/app/App";
import { d_searchpage_logo, d_searchpage_logo_head, searchpage_logo_head } from "../../images/applogos";
import { Icon } from "../../components/Component";
import Notification from "./dropdown/notification/Notification";
import { getCookie } from "../../utils/Utils";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { GlobalContext } from "../Index";
import { fetchAPI } from "../../utils/api_helper";

const Header = ({ fixed, className }) => {
  const { isSearchNewsVisible, issearchdiv, onTrendingClick } = useContext(GlobalContext);

  const theme = useTheme();
  const { skin } = useTheme();
  const themeUpdate = useThemeUpdate();

  const [authenticated, setAuthenticated] = useState(false);
  const [fullScreenOn, setFullScreenOn] = useState(false);

  useEffect(() => {
    const isAuthenticated = getCookie("authToken");
    setAuthenticated(isAuthenticated);
  }, []);

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const data = new FormData();
        data.append("action", "fetch");
        const response = await fetchAPI(data, "api/v1/public/frontend-settings");
        if (response.status) {
          const fetchedTheme = response?.data?.frontend_settings?.theme;
          themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTheme();
  }, [fullScreenOn]);

  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  // State for time and date
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day},${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSignIn = () => {
    // Redirect to the account URL for signing in
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}login`;
    window.location.href = logoutUrl;
  };

  const isPrivacyPolicyPage =
    window.location.href.includes("/privacy-policy") || window.location.href.includes("/terms-condition");

  return (
    <>
      <section
        style={{ zIndex: 10 }}
        className={`section-header  position-sticky top-0 ${
          window.location.href.includes("/apps") ? "apps-header" : ""
        }`}
      >
        <div className={`${headerClass} border-0`}>
          <div className="container-fluid">
            <div className="nk-header-wrap align-items-center d-flex py-1 position-relative">
              {/* <div className="nk-menu-trigger d-xl-none ms-n1">
                <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
              </div> */}
              {isPrivacyPolicyPage ? (
                <div className="silo-logo me-2">
                  <img src={skin === "dark" ? d_searchpage_logo_head : searchpage_logo_head} alt="" height="40" />
                </div>
              ) : (
                ""
              )}
              <div className="lh-17px">
                <p className="mb-0 fw-bold text-white">{time}</p>
                <p className="fs-9px mb-0 text-white">{date}</p>
              </div>
              <div>
                <Link to="https://calendar.silocloud.io/">
                  <Icon name="calender-date" className="head-icon text-white" />
                </Link>
              </div>
              {!issearchdiv && (
                <div className="silo-header-logo">
                  {/* <img src={d_searchpage_logo} alt="Search Page Logo" height="54" /> */}
                  <img src={skin === "dark" ? d_searchpage_logo_head : searchpage_logo_head} alt="" height="54" />
                </div>
              )}

              <div className="nk-header-tools align-items-center py-0">
                <ul className="nk-quick-nav">
                  <li className="trending-mode fs-14px pointer d-none d-lg-block" onClick={onTrendingClick}>
                    {isSearchNewsVisible ? <span className="trending-button">Trending</span> : "Trending"}
                  </li>
                  <li className="mt-1 d-none d-lg-block">
                    {fullScreenOn ? (
                      <Icon name="minimize" className="pointer screenmode fs-4" onClick={toggleFullScreen} />
                    ) : (
                      <Icon name="maximize" className="pointer screenmode fs-4" onClick={toggleFullScreen} />
                    )}
                  </li>
                  <li className="notification-dropdown">
                    <Notification />
                  </li>
                  <AppDropdown />

                  {!authenticated ? (
                    <li className="d-flex justify-content-end align-items-center ">
                      <button className="btn sign-in-btn h-25" onClick={handleSignIn}>
                        Sign In
                      </button>
                    </li>
                  ) : (
                    <li className="user-dropdown d-flex">
                      <User />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
