import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { Block, Icon } from "../components/Component";
import "./css/app.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTheme } from "../layout/provider/Theme";
import profiledp from "../images/applogos/black_theme/dp.png";
import {
  cloud_mic,
  cloud_search,
  d_searchpage_logo,
  mikelogo_dark,
  mikelogo_light,
  searchpage_logo,
  web_search,
  web_search_w,
} from "../images/applogos";
import { fetchCloudSearchApi } from "../utils/api_helper";
import axios from "axios";
import SearchNews from "./SearchNews";
import { GlobalContext } from "../layout/Index";
import voiceimage from "../images/applogos/voice_new.png";

function SearchPage() {
  const { isSearchNewsVisible, searchNewsRef, issearchdiv, selectedLogoImage } = useContext(GlobalContext);
  const [modalZoom, setModalZoom] = useState(false);
  const [listening, setListening] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCloudResults, setLoadingCloudResults] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchArray, setSearchArray] = useState([]);
  const [isserachmodedropdown, setIsSerachModeDropdown] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null); // For managing the timeout

  const { skin } = useTheme();
  const toggleZoom = () => setModalZoom(!modalZoom);
  const dropdownRef = useRef(null);

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const cx = process.env.REACT_APP_GOOGLE_CX;

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleSearch = (searchText) => {
    setQuery(searchText);
    setCurrentPage(1);
    if (isChecked) {
      setLoadingCloudResults(true);
      fetchCloudSearchApi(`api/v1/silo-home/cloud-search?searchParam=${searchText}&searchType=all`)
        .then((result) => {
          setLoadingCloudResults(false);
          setSearchArray(result.data?.result);
          setIsSerachModeDropdown(true); // Ensure dropdown is shown
        })
        .catch((error) => {
          console.error("Error fetching cloud search results", error);
          setLoadingCloudResults(false);
        });
    } else {
      setModal(true);
      fetchGoogleSearchResults(searchText, 1);
    }
  };

  const fetchGoogleSearchResults = async (searchText, pageNumber) => {
    setLoading(true);
    try {
      const startIndex = (pageNumber - 1) * 10 + 1;
      const exactTerms = "social media";
      const response = await axios.get(
        `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${cx}&q=${searchText}&start=${startIndex}`
      );
      setResults(response.data.items);
    } catch (error) {
      console.error("Error fetching search results", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = useCallback(
    debounce(async (event) => {
      const searchText = event.target.value;
      if (searchText) {
        if (searchText.length > 0) {
          setIsSerachModeDropdown(true);
        } else {
          setIsSerachModeDropdown(false);
        }
        handleSearch(searchText);
      }
    }, 2000),
    [isChecked]
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission if inside a form
      handleSearch(query); // Trigger search on Enter key press
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSerachModeDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.id === "switchcloud");
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchGoogleSearchResults(query, pageNumber);
  };

  // Adding voice recognition functionality
  useEffect(() => {
    if (modalZoom) {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;

      const timeoutDuration = 7000; // Set timeout duration (in seconds)

      recognition.onresult = (event) => {
        const result = event.results[0][0].transcript;
        handleSearch(result); // Trigger search with the voice input
        setModalZoom(false);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event);
        setModalZoom(false);
      };

      recognition.start();
      setListening(true);

      // Clear any existing timeout when recognition starts
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to close the modal if no command is given within the duration
      const id = setTimeout(() => {
        recognition.stop();
        setListening(false);
        setModalZoom(false);
      }, timeoutDuration);

      setTimeoutId(id);

      return () => {
        clearTimeout(id);
        recognition.stop();
        setListening(false);
      };
    }
  }, [modalZoom]);

  const toggleModal = () => setModal(!modal);

  return (
    <React.Fragment>
      <Head title="Silo Search" />
      <Content>
        <Block>
          <div
            className={`d-flex justify-content-center align-items-center flex-column silosearchblock ${
              issearchdiv ? "" : "d-none"
            }`}
          >
            <div className="silo-logo">
              {/* <img src={skin === "dark" ? d_searchpage_logo : searchpage_logo} alt="" height="140" /> */}
              <img src={selectedLogoImage} alt="" height="140" />
            </div>

            <div className="silo-search position-relative">
              <div className="input-group bg-theme google-search-bar d-flex rounded-pill position-relative z-3">
                <span className="input-group-text border-0" id="search-addon">
                  <img
                    src={isChecked ? cloud_search : skin === "dark" ? web_search_w : web_search}
                    height="22px"
                    alt="search"
                  />
                </span>

                <input
                  type="text"
                  className="form-control silo-search-box border-0 h-100"
                  placeholder={isChecked ? "Search in SiloCloud..." : "Search..."}
                  aria-label="Search"
                  aria-describedby="search-addon"
                  id="query"
                  autoComplete="off"
                  value={query}
                  onInput={handleInputChange}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <img
                  src={isChecked ? cloud_mic : skin === "dark" ? mikelogo_light : mikelogo_dark}
                  height="22px"
                  className="me-3 ms-2 voice-search-icon"
                  onClick={toggleZoom}
                  alt="mic-search"
                />

                <Modal isOpen={modal} toggle={toggleModal} className="search-result" size="xl">
                  <ModalHeader toggle={toggleModal}>Search Results</ModalHeader>
                  <ModalBody className="pb-5">
                    <ul className="p-0">
                      {results === undefined || results === null ? (
                        <li>No results Found</li>
                      ) : (
                        results?.map((result, index) => (
                          <li key={index}>
                            <div className="mt-3 mb-1 d-flex g-2">
                              <div className="place-holder-image">
                                {result.pagemap?.cse_thumbnail && (
                                  <img src={result?.pagemap?.cse_thumbnail[0].src} alt={result.title} />
                                )}
                              </div>
                              <div>
                                <p className="mb-0  lh-10px">{result.title}</p>
                                <a href={result.link} className="fs-11px text-decoration-none opacity-75">
                                  {result.link}
                                </a>
                              </div>
                            </div>
                            <a
                              href={result.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="fs-4 text-decoration-none google-text-color"
                            >
                              {result.title}
                            </a>
                            <p className="fs-13px google-subtext-color">{result.snippet}</p>
                          </li>
                        ))
                      )}
                    </ul>
                    {results !== undefined && results !== null && (
                      <div className="pagination justify-content-center mt-3">
                        {[...Array(10)].map((_, index) => (
                          <button
                            key={index + 1}
                            className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                            onClick={() => handlePageClick(index + 1)}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    )}
                  </ModalBody>
                </Modal>
              </div>

              {/* Voice recognition modal */}
              <Modal isOpen={modalZoom} className="voice-audio" toggle={toggleZoom} fullscreen>
                <ModalHeader
                  toggle={toggleZoom}
                  close={
                    <button className="close" onClick={toggleZoom}>
                      <Icon name="cross" />
                    </button>
                  }
                  className="border-0"
                ></ModalHeader>
                <ModalBody className="d-flex justify-content-center align-items-center">
                  <div className="voice-content">
                    <div className="box d-inline-block">
                      <img src={voiceimage} alt="Voice Search" />
                    </div>
                    <div>
                      {" "}
                      <h5 className="speak"> Listening... </h5>
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              {isserachmodedropdown && (
                <div ref={dropdownRef} className={`search-dropdown ${query ? "" : "d-none"}`}>
                  {!loadingCloudResults && isChecked && (
                    <>
                      {searchArray && searchArray?.length > 0 ? (
                        <ul className={`link-list-opt no-bdr`}>
                          {searchArray?.map((item, index) => (
                            <React.Fragment key={index}>
                              {item.data.length > 0 && (
                                <h6 className="px-3 pt-2 text-muted fw-normal my-2">
                                  {item.type
                                    .split("_")
                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(" ")}
                                </h6>
                              )}
                              {item?.data?.map((subItem, subIndex) => (
                                <li key={subIndex} className="search-dropdown-item">
                                  {item.type === "marketplace_products" && (
                                    <div>
                                      <a
                                        href={`https://store.silocloud.io/${subItem?.id}/${subItem?.product_name}`}
                                        target="_blank"
                                      >
                                        <img src={process.env.REACT_APP_API_URL + "storage/" + subItem?.thumbnail} />
                                        <span className="ps-2">{subItem?.product_name}</span>
                                      </a>
                                    </div>
                                  )}
                                  {item.type === "silo_apps" && (
                                    <div>
                                      <a href={subItem?.project_link} target="_blank">
                                        <img src={subItem?.image_link} />
                                        <span className="ps-2">{subItem?.name}</span>
                                      </a>
                                    </div>
                                  )}
                                  {item.type === "user_profiles" && (
                                    <div>
                                      <a href="#blank" className="pe-none">
                                        {subItem?.user_profile === null || subItem?.user_profile === "null" ? (
                                          <img src={profiledp} />
                                        ) : (
                                          <img src={subItem?.user_profile} />
                                        )}
                                        <span className="ps-2">{subItem?.username}</span>
                                      </a>
                                    </div>
                                  )}
                                  {item.type === "channels" && (
                                    <div>
                                      <a href="#blank" className="pe-none">
                                        <img src={subItem?.logo} />
                                        <span className="ps-2">{subItem?.channel_name}</span>
                                      </a>
                                    </div>
                                  )}
                                  {item.type === "stores" && (
                                    <div>
                                      <a href="#blank" className="pe-none">
                                        <img src={process.env.REACT_APP_API_URL + subItem?.logo} />
                                        <span className="ps-2">{subItem?.name}</span>
                                      </a>
                                    </div>
                                  )}
                                </li>
                              ))}
                            </React.Fragment>
                          ))}
                        </ul>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="p-3 text-primary">No results found</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="switches-container bg-theme mt-4 mx-auto position-relative d-flex p-0 z-1">
              <input
                type="radio"
                id="switchweb"
                name="switchPlan"
                value="Monthly"
                checked={!isChecked}
                onChange={handleCheckboxChange}
              />
              <input
                type="radio"
                id="switchcloud"
                name="switchPlan"
                value="Yearly"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="switchweb">Web</label>
              <label htmlFor="switchcloud">Cloud</label>
              <div className="switch-wrapper">
                <div className="switch">
                  <div>Web</div>
                  <div>Cloud</div>
                </div>
              </div>
            </div>
          </div>

          <div>{isSearchNewsVisible && <SearchNews ref={searchNewsRef} />}</div>
        </Block>
      </Content>
    </React.Fragment>
  );
}

export default SearchPage;
